<template>
  <div>
        <NForm inline ref="searchFormRef" label-placement="left">
          <NFormItem label="搜索：">
            <NInput
              maxlength="20"
              show-count
              clearable
              v-model:value="searchParams['school_name']"
              @keyup.enter="handleSearch"
            />
          </NFormItem>
          <!-- 类型 习题 试卷 -->
          <!-- <NFormItem label="类型：">
            <div style="width: 200px">
              <NSelect
                :options="typeOptions1"
                v-model:value="searchParams['School[state]']"
              />
            </div>
          </NFormItem> -->
  
          <NFormItem>
            <NSpace>
              <NButton type="primary" @click="handleSearch">搜索</NButton>
              <NButton @click="handleClear">清除</NButton>
            </NSpace>
          </NFormItem>
        </NForm>
        <!-- 资源表格 -->
        <NDataTable
          size="small"
          :columns="columns"
          :row-key="(row) => row.id"
          :data="ZtableData"
          remote
          :pagination="pagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          :default-checked-row-keys="ZselectedIdsArr"
          @update:checked-row-keys="ZhandleSelect"
        ></NDataTable>

    <NSpace
      justify="end"
      style="
        margin: 12px -28px 0;
        padding: 15px 28px 0;
        border-top: 1px solid #ddd;
      "
    >
      <span style="line-height: 34px; color: #999"
        >已选：<span class="theme-color">{{
          ZselectedIdsArr.length 
        }}个学校</span></span
      >
      <NButton @click="emit('modal-close')">取消</NButton>
      <NButton
        type="primary"
        :disabled="ZselectedIdsArr.length == 0"
        @click="nextStep"
        >下一步</NButton
      >
    </NSpace>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineExpose, computed, h } from "vue";

import { NSpace, NButton, NTime } from "naive-ui";

import PageLoading from "@/components/PageLoading/index.vue";

import { getSchoolList } from "@/api/school.js";

import { resStatusEnum } from "@/enumerators/http.js";

const { SUCCESS } = resStatusEnum;

const loading = ref(false);
const emit = defineEmits([
  "step-next",
  "id-label-update",
  "id-jlabel-update",
  "modal-close",
]);
const props = defineProps({
  labelType: {
    default: "",
  },
});

// 类型
const typeOptions1 = ref([
  {
    label: "资源",
    value: 1,
  },
  {
    label: "习题",
    value: 2,
  },
  { label: "试卷", value: 3 },
  { label: "全部", value: 4 }
]);

function handleTabBeforeLeave() {
  return !loading.value;
}

const tableDataCache = (() => {
  const cacheObj = {};
  return reactive(cacheObj);
})();

const nextStep = () => {
  emit("step-next");
};
// 组件间传值
const resetStep = () => {
  ZhandleSelect([]);
  JhandleSelect([]);
};
defineExpose({
  handleClear,
  resetStep,
});

const getDefaultSearchParams = () => ({
  "School[id]": localStorage.getItem("schoolId"), //学校ID
  "School[label]": [], // 选中的标签ID
  "School[material]": [], // 选中的教材ID
  "school_name": "",
  "status":'1'
});
const searchFormRef = ref(null);
const searchParams = reactive(getDefaultSearchParams());

const handleSearch = () => {
  handlePageChange(1);
};
const handleClear = () => {
  Object.assign(searchParams, getDefaultSearchParams());
  handleSearch();
};

// 数据集 按拼音的第一个字符
const tableData = ref([]);

const ZtableData = ref([]); // 资源数据
const temporaryData = ref([]); // 临时资源数据

// 资源表格内容
const columns = [
  { type: "selection" ,key: "id"},
  { title: "学校名称", key: "school_name" },
  { title: "学生数", key: "student_num" },
  { title: "授课教师数", key: "teacher_num" }
];

const pagination = reactive({
  page: 1,
  itemCount: 0,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;
};
const handlePageChange = (page) => {
  pagination.page = page;
  searchParams["page"] = page;
  updateList();
};
const handlePageSizeChange = (pageSize) => {
  pagination.pageSize = pageSize;
  searchParams["page_size"] = pageSize;
  handlePageChange(1);
};
// 数据初始化
const updateList = () => {
  loading.value = true;
  let id = localStorage.getItem("schoolId");
  getSchoolList(searchParams)
    .then((res) => {
      loading.value = false;
      if (res.code === SUCCESS) {
        ZtableData.value = res.data.list
        updateItemCount(res.data.total);
        // console.log("res",res)
        if(searchParams["page"]!=1){
          let data = temporaryData.value.concat(res.data.list)
          temporaryData.value = data
          ZupdateIdLabelMap(data);
        }else{
          let data = temporaryData.value.concat(res.data.list)
          temporaryData.value = data
          ZupdateIdLabelMap(temporaryData.value);
        }
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
onMounted(() => {
  handleClear();
});

// 原传值1 
const ZselectedIdsArr = ref([]);
const ZidLabelMap = {};
var ZData = [];
var Zlist = [];
const ZupdateIdLabelMap = (list) => {
  ZData = list;
  Array.isArray(list) &&
    list.forEach((elem) => {
      ZidLabelMap[elem.id] = elem.title;
    });
};

const ZhandleSelect = (idsArr) => {
  Zlist = [];
  ZselectedIdsArr.value = idsArr;
  // console.log('ZData',ZData);
  ZSelective(idsArr,ZData)
};

function ZSelective(idsArr,ZData) {
  let ArrID = []
  for (var j = 0; j < ZData.length; j++){
    for (var i = 0; i < idsArr.length; i++){
    if(ZData[j].id == idsArr[i]){
      let data = {
        id: ZData[j].id,
        title: ZData[j].school_name,
      }
      if(ArrID.indexOf(idsArr[i])== -1){
        ArrID.push(ZData[j].id);
        Zlist.push(data);
      }
    }
  }
  }
  emit("id-label-update", Zlist)
}

// 原传值2
const JselectedIdsArr = ref([]);
const JidLabelMap = {};
var JData = [];
var Jlist = [];
const JupdateIdLabelMap = (list) => {
  JData = list
  Array.isArray(list) &&
    list.forEach((elem) => {
      JidLabelMap[elem.id] = elem.title;
    });
};
const JhandleSelect = (idsArr) => {
  Jlist = []
  JselectedIdsArr.value = idsArr;
  JSelective(idsArr,JData)
};

function JSelective(idsArr,JData) {
  let ArrID = []
  for (var j = 0; j < JData.length; j++){
    for (var i = 0; i < idsArr.length; i++){
    if(JData[j].id == idsArr[i]){
      let data = {
        id: JData[i].id,
        title: JData[i].title,
        name:JData[i].admin_username
      }
      
      if(ArrID.indexOf(idsArr[i])== -1){
        ArrID.push(JData[j].id);
        Jlist.push(data);
      }
    }
  }
  }
  emit("id-jlabel-update", Jlist);
}

// 名称转换
function getResourceName(status) {
  // (全部显示参数不传 1资源，2习题，3试卷)
  if (status == "1") {
    return "资源";
  } else if (status == "2") {
    return "习题";
  } else if (status == "3") {
    return "试卷";
  }
}

</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.theme-color {
  color: @theme-color;
}
.pane-num {
  background: red;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  text-align: center;
  position: absolute;
  line-height: 11px;
}
.pane-num-1 {
  top: 62px;
  left: 29px;
}
.pane-num-2 {
  top: 62px;
  left: 94px;
}
.pane-num-3 {
  top: 62px;
  left: 158px;
}
.pane-num-4 {
  top: 62px;
  left: 264px;
}
</style>